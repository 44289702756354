import React from 'react';
import FlavorText from './flavor-text';
import { FaPray } from 'react-icons/fa';



const Home = (props) => <>
  <section id="home">
    <h1><FaPray/>Who was Dr. Bill Burks?</h1>
    <p>Dr. Bill Burks of Fort Worth, Texas, provided both audio, and textual sermons. Pastor Bill Burks' ministry was varied. He taught Sabbath school and delighted in explaining Bible principles. He studied Greek and Hebrew and enjoyed sharing insights from both languages.</p>
    <p>Pastor Burks prayed for people and preached in a way that even children could understand the gospel message. He brought concepts of faith down to their level and still kept the interest of their parents.</p>
    <p>Pastor Burks went out of his way to assist folks when necessary and worked with church leaders to present a united front to the community. He had the skills from his secular job to reach people with the Word of God. </p>
    <p>Pastor Burks adhered to sound doctrine and could explain where cults may deviate from the simple New Testament faith of the time of Jesus Christ.</p>
    <p>A leader at our church conference summed Pastor Bill Burks up in just two words one year: "Good thinker."</p>
    <FlavorText book="Psalm" chapter="37" verse="39" text="But the salvation of the righteous is of the LORD"/>
  </section>
</>


export default Home;
